import AthenaClass from "./athenaClass";

export default class AthenaRow {
  agency: string;
  custom_mapping: string;

  public static toAthenaClass(rows: AthenaRow[]): AthenaClass {
    const max_level = this.get_max_level(rows[0]);
    const keys = Object.keys(rows[0]);
    const tree = {};
    const startIndex = rows[0].agency === "agency" ? 1 : 0; // check if the first row is a header

    //skip header
    for (let i = startIndex; i < rows.length; i++) {
      const nodes = [];
      const row = rows[i];
      // Here we assume that the query header is formatted like this
      // agency, custom_mapping, level_1, level_1_id, level_2, level_2_id, ...., level_n, level_n_id
      const stop = 2 * max_level + 2;
      const custom_mapping = row.custom_mapping === 'true';
      for (let level = 2; level < stop; level += 2) {
        const node = {
          id: row[keys[level + 1]],
          customMapping: custom_mapping,
          body: row[keys[level]],
        };
        nodes.push(node);
      }
      this.traverse_with_node(tree, nodes);
    }

    let res = this.recursive_dict_to_list(tree);
    return res.children[0];
  }

  private static get_max_level(row: Object): number {
    const keys = Object.keys(row);
    const re = /^level_([0-9])_id$/;
    const match = keys[keys.length - 1].match(re);
    const level = +match[1];

    return level;
  }

  private static traverse_with_node(treeNode, nodes: any[]) {
    if (nodes.length === 0) {
      return nodes;
    }
    let node = nodes[0];
    nodes = nodes.slice(1);
    if (treeNode["children"] && treeNode["children"][node["id"]]) {
      node = treeNode["children"][node["id"]];
    } else {
      if (!("children" in treeNode)) {
        treeNode["children"] = {};
      }
      treeNode["children"][node["id"]] = node;
    }
    this.traverse_with_node(node, nodes);
  }

  private static recursive_dict_to_list(node: any): AthenaClass {
    if ("children" in node) {
      node.children = Object.values(node["children"]).map((v) =>
        this.recursive_dict_to_list(v)
      );
      
      // If any children has custom mapping parent should also have
      node.customMapping =
        node.children.map((x) => x.customMapping).lastIndexOf(true) > -1;
    }
    const result = node as AthenaClass;
    return result;
  }
}
