import { Component, Vue } from 'vue-property-decorator';

import AthenaClass from '@/models/import/athenaClass';
import DynamoClass from '@/models/import/dynamoItems';

import axios, { AxiosResponse } from 'axios';
import ITreeItem from '@/models/iTreeItem';
import TreeItem from '@/models/TreeItem';
import DynamoItems from '@/models/import/dynamoItems';
import DynamoItem from '@/models/import/dynamoItem';
import AthenaRow from '@/models/import/athenaRow';

@Component
export default class DataSourceComponent extends Vue {

    public client: TreeItem = new TreeItem();
    public isLoading: boolean = false;
    public loadingMessage: string = 'Loading data';
    public isUpdating: boolean = false;
    public filterVal: string = '';
    public currentPage: number = 0;
    public minPage:number = 0;
    public maxPage:number = 1;
    

    private apiUrl: string = process.env.VUE_APP_API_URL;
    private originalData: DynamoItems = null;

    public created() {
       this.loadPage(this.currentPage)
    }

    public loadPage(page:number, search?: string){
        const country = this.$route.params.country;
        const accountId = this.$route.params.accountId;
        const datasource = this.$route.params.datasource;
        const encodeAccountId = encodeURIComponent(accountId);
        const params = { page }

        if(search){
            params['search'] = search;
        }
        
        this.isLoading = true;
        const promises = [axios.get(`${this.apiUrl}/${datasource}/${country}/${encodeAccountId}/athena`, {
            params
        })];
        if(this.originalData === null){
            promises.push(axios.get(`${this.apiUrl}/${datasource}/${country}/${encodeAccountId}/dynamo`))
        }
        

        Promise.all(promises)
            .then((payload) => this.client = this.mergeData(payload))
            .catch((err) => this.handleError(err))
            .finally(() => this.isLoading = false);
    }


    public nextPage(){
        if (this.currentPage >= this.maxPage){
            return;
        }
        this.currentPage++;
        this.client = new TreeItem();
        this.loadPage(this.currentPage);
    }

    public previousPage(){
        if (this.currentPage <= this.minPage){
            return;
        }
        this.currentPage--
        this.client = new TreeItem();
        this.loadPage(this.currentPage);
    }

    public sendUpdate(): void {
        this.isUpdating = true;
        const country = this.$route.params.country;
        const accountId = this.$route.params.accountId;
        const datasource = this.$route.params.datasource;
        // TODO filter only changed data
        // TODO PK maybe not
        const putObject = this.recursiveGetLeafs(this.client, '');
        const filteredItems = this.removeNonUpdates(putObject);
        const items = new DynamoItems();
        items.items = filteredItems;
        axios.put(`${this.apiUrl}/${datasource}/${country}/${accountId}/dynamo`, JSON.stringify(items))
            .then(() => alert(`Updated ${Object.keys(filteredItems).length} items.`))
            .catch((err) => this.handleError(err))
            .finally(() => this.isUpdating = false);
    }


    public removeNonUpdates(putObject: { [key: string]: DynamoItem; }): { [key: string]: DynamoItem; } {
        const updatedItems: { [key: string]: DynamoItem; } = {};

        for (const [key, value] of Object.entries(putObject)) {
            const originalObject = this.originalData.items[key];
            if (originalObject) {
                // Object changed
                if (value.campaignId !== originalObject.campaignId || value.isExcluded !== originalObject.isExcluded) {
                    updatedItems[key] = value;
                }
            } else {
                if (value.campaignId !== 0 || value.isExcluded) {
                    updatedItems[key] = value;
                }
            }
        }

        return updatedItems;
    }

    public recursiveGetLeafs(node: ITreeItem, parentId: string): { [key: string]: DynamoItem } {

        // is leaf
        if (node.getChildren().length === 0) {
            const id = `${node.id}#${parentId}`;
            const result = { [id]: DynamoItem.treeItemToDynamo(node) };
            return result;
        } else {
            let children: { [key: string]: DynamoItem } = {};
            node.getChildren().forEach((child) => {
                const childList = this.recursiveGetLeafs(child, node.id);
                children = Object.assign(children, childList);
            });
            return children;
        }

    }


    public filter() {
        this.client = new TreeItem();
        this.currentPage = 0;
        this.loadPage(this.currentPage, this.filterVal.toLowerCase())
    }

    private mergeData(payload: AxiosResponse<any>[]): TreeItem {
        const search = payload[0].config.params.search;
        const isSearch  = search && search.length > 0;
        const isDataEmpty = payload[0].data.rows.length === 0;
        const client = new TreeItem();

        if(isDataEmpty && isSearch) {
            alert('No data matched your filter');
            return client;
        }

        if(isDataEmpty && !isSearch) {
            alert('No data found for the client');
            return client;
        }

        
        const athenaPage = payload[0].data;
        this.maxPage =  athenaPage.totalPages
        const athenaData = AthenaRow.toAthenaClass(athenaPage.rows);


        if(payload.length > 1){
            this.originalData = payload[1].data as DynamoItems;
        }
        
        const dynamoData = this.originalData

        
        if (!athenaData) {
            alert('No Client data');
            return client;
        }
        client.isExcluded = false; //  ? false : dynamoData.isExcluded;
        client.id = athenaData.id;
        client.body = athenaData.body;
        client.customMapping = false;
        client.campaignId = '';
        client.children = athenaData.children.map((dlCamp) => this.mergeNodes(dlCamp, athenaData.id, dynamoData));
        return client;
    }

    private mergeNodes(athenaData: AthenaClass, parentId: string, dynamoItems: DynamoItems): TreeItem {
        if (!athenaData) {
            return null;
        }

        const node = new TreeItem();
        let dynamoItem: DynamoItem = null;
        const isLeaf = athenaData.children === undefined || athenaData.children.length === 0;

        if (isLeaf) {
            dynamoItem = dynamoItems.items[`${athenaData.id}#${parentId}`];
        }

        node.id = athenaData.id;
        node.body = athenaData.body;
        node.campaignId = dynamoItem ? dynamoItem.campaignId + '' : '0';
        node.customMapping = athenaData.customMapping;
        node.children = athenaData.children
            ? athenaData.children.map((x) => this.mergeNodes(x, athenaData.id, dynamoItems)) : [];

        if (isLeaf) {
            node.isExcluded = !dynamoItem ? false : dynamoItem.isExcluded;
            node.isPreviousExcluded = node.isExcluded;
        } else {
            node.isExcluded = node.children.findIndex((x) => !x.isExcluded) === -1;
            node.isPreviousExcluded = node.children.findIndex((x) => !x.isPreviousExcluded) === -1;
        }
        return node;
    }


    private handleError(error: any): void {
        try {
            if (error.response.status === 400) {
                alert(error.response.data);
            } else {
                alert('Server error. Contact Admin');
            }
        } catch {
            alert('Server error. Contact Admin');
        }
    }

}
