import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { EVENT_BUS } from './../../eventBus';

import ITreeItem from './../../models/iTreeItem';


@Component(
    { name: 'tree-node' },
)
export default class TreeComponent extends Vue {
    @Prop()
    public treeItem: ITreeItem;

    @Prop()
    public parentExcluded: boolean;

    @Prop()
    public collapseCounter: number;

    @Prop()
    public parentCampaignId: number;

    public propagateChildren: boolean = false;
    public checkboxClicked: boolean;
    public isCollapsed: boolean = true;
    public showPreviouslyExcluded: boolean = true;
    public customMappingFilter: boolean = false;
    public customMappingUnmappedFilter: boolean = false;

    public created() {

        this.showPreviouslyExcluded = !this.treeItem.isPreviousExcluded;

        EVENT_BUS.$on('exclude:show', () => this.showPreviouslyExcluded = true);
        EVENT_BUS.$on('exclude:hide', () => {
            if (this.treeItem.isPreviousExcluded) {
                this.showPreviouslyExcluded = false;
            }
        });
        EVENT_BUS.$on('customMapping:show', () => this.customMappingFilter = false);
        EVENT_BUS.$on('customMapping:hide', () => this.customMappingFilter = true
            && !this.treeItem.customMapping
            && this.collapseCounter < 1);

        EVENT_BUS.$on('customMapping:unmapped:show', () => this.customMappingUnmappedFilter = false);
        EVENT_BUS.$on('customMapping:unmapped:hide', () => this.customMappingUnmappedFilter =
            this.recursive_has_missing(this.treeItem) && this.collapseCounter < 1);

        this.isCollapsed = this.collapseCounter < 1;


    }

    @Watch('parentExcluded')
    public onParentExcludedChanged(value: boolean, _oldValue: boolean) {
        this.treeItem.isExcluded = value;
        this.propagateChildren = value;
    }
    @Watch('parentCampaignId')
    public onParentCampaignIdChanged(value: string, _: boolean) {
        this.treeItem.campaignId = value;
    }

    public toggle() {
        this.isCollapsed = !this.isCollapsed;
    }

    public propagate() {
        this.propagateChildren = !this.treeItem.isExcluded; // update Children
        this.checkboxClicked = true;
    }

    public isLeaf() {
        return !this.treeItem.getChildren() || this.treeItem.getChildren().length === 0;
    }

    private recursive_has_missing(node: ITreeItem): boolean {

        if (!node.getChildren() || node.getChildren().length === 0) { // isLeaf
            return node.customMapping && +node.campaignId > 0;
        }
        return node.getChildren().reduce((acc, x) => acc && this.recursive_has_missing(x), true);
    }
}
