import { Component, Vue } from 'vue-property-decorator';
import { EVENT_BUS } from '../../eventBus';

@Component
export default class ShowCustomMappingComponent extends Vue {

  public filterCustom: boolean = false;
  public filterMissing: boolean = false;

  public filterCustomMapping() {
    if (this.filterCustom) {
      EVENT_BUS.$emit('customMapping:hide');
    } else {
      EVENT_BUS.$emit('customMapping:show');
    }
  }

  public filterMissingMapping() {
    if (this.filterMissing) {
      EVENT_BUS.$emit('customMapping:unmapped:hide');
    } else {
      EVENT_BUS.$emit('customMapping:unmapped:show');
    }
  }
}
