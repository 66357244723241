import ITreeItem from './iTreeItem';


export default class TreeItem implements ITreeItem {
    private _isPreviousExcluded: boolean;
    private _isExcluded: boolean;
    private _isFiltered: boolean;
    private _body: string;
    private _id: string;
    private _campaignId: string;
    private _customMapping: boolean;
    private _children: TreeItem[];

    public constructor() {
        this._isPreviousExcluded =  false;
        this._isExcluded = false;
        this._isFiltered = false;
        this._body = '';
        this._id = '0';
        this._children =  [];
        this._campaignId = '0';
    }

    public get isPreviousExcluded(): boolean {
        return this._isPreviousExcluded;
    }
    public set isPreviousExcluded(value: boolean) {
        this._isPreviousExcluded = value;
    }
    public get isExcluded(): boolean {
        return this._isExcluded;
    }
    public set isExcluded(value: boolean) {
        this._isExcluded = value;
    }
    public get isFiltered(): boolean {
        return this._isFiltered;
    }
    public set isFiltered(value: boolean) {
        this._isFiltered = value;
    }

    public get body(): string {
        return this._body;
    }
    public set body(value: string) {
        this._body = value;
    }
    public get id(): string {
        return this._id;
    }
    public set id(value: string) {
        this._id = value;
    }

    public get campaignId(): string {
        return this._campaignId;
    }
    public set campaignId(value: string) {
        this._campaignId = value;
    }

    public get customMapping(): boolean {
        return this._customMapping;
    }
    public set customMapping(value: boolean) {
        this._customMapping = value;
    }

    public get children(): TreeItem[] {
        return this._children;
    }
    public set children(value: TreeItem[]) {
        this._children = value;
    }

    public displayText(): string {
        return this._body;
    }

    public headerText(): string {
        return this._id + '';
    }

    public getChildren(): ITreeItem[] {
        return this._children;
    }
}
