import ITreeItem from '../iTreeItem';

export default class DynamoItem {


    public static treeItemToDynamo(child: ITreeItem): DynamoItem {
        const dynamoItem = {
            isExcluded: child.isExcluded,
            campaignId: +child.campaignId,
        };

        return dynamoItem;
    }

    public isExcluded: boolean;
    public campaignId: number;
}
