import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './registerServiceWorker';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCaretSquareDown, faCaretSquareRight, faSearch, faEnvelope,
    faArrowCircleRight, faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import TreeComponent from './components/TreeItemComponent/TreeComponent';
import ShowExcludedComponent from './components/ShowExcludedComponent/showExcludedComponent';
import showCustomComponent from './components/ShowCustomMappingComponent/ShowCustomMappingComponent';
Vue.use(BootstrapVue);

library.add(faCaretSquareDown, faCaretSquareRight, faSearch, faEnvelope, faArrowCircleRight, faArrowCircleLeft);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('tree-node', TreeComponent);
Vue.component('show-excluded', ShowExcludedComponent);
Vue.component('show-custom-only', showCustomComponent);

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
