






import { Component, Vue } from 'vue-property-decorator';
import AdformMediaComponent from '@/components/AdformMediaComponent/adformMediaComponent.vue'; // @ is an alias to /src

@Component({
  components: {
    AdformMediaComponent,
  },
})
export default class AdformMedia extends Vue {}
