import { Component, Vue } from 'vue-property-decorator';

import AthenaClass from '@/models/import/athenaClass';
import DynamoItems from '@/models/import/dynamoItems';
import MediaClass from '@/models/import/mediaClass';

import axios from 'axios';
import ITreeItem from '@/models/iTreeItem';
import TreeItem from '@/models/TreeItem';

@Component
export default class DataSourceComponent extends Vue {

    public isLoading: boolean = false;
    public pageNr: number = 0;
    public filterVal: string = '';
    private apiUrl: string = process.env.VUE_APP_API_URL;
    private datasource: string = 'adform';
    private items: TreeItem[] = [];

    public created() {
        this.isLoading = true;
        this.loadPage(0);

    }

    public loadPage(modifier: number): void {

        this.isLoading = true;
        this.items = [];
        this.pageNr = Math.max(1, this.pageNr + modifier);
        const filter = this.filterVal.trim() === '' ? '' : `&filter=${this.filterVal.toLocaleLowerCase()}`;
        axios.get(`${this.apiUrl}/${this.datasource}/media?pageSize=16&page=${this.pageNr}${filter}`)
            .then((payload) => this.processMediaData(payload.data))
            .catch((err) => this.handleError(err))
            .finally(() => this.isLoading = false);
    }


    public saveItem(item: TreeItem): void {
        // const body = DynamoItems.treeItemToDynamo(item);
        // const mediaUri = encodeURIComponent(item.id);
        // axios
        //     .put(`${this.apiUrl}/${this.datasource}_media/${mediaUri}/dynamo`, JSON.stringify(body))
        //     .catch((err) => this.handleError(err))
        //     .finally(() => this.isLoading = false);

    }

    private processMediaData(data: MediaClass) {
        if (data.rows.length === 0) {
            alert('No results');
            this.items = [];
            return;
        }
        const promises = [];
        data.rows.forEach((row) => {
            promises.push(
                axios.get(`${this.apiUrl}/${this.datasource}_media/${row.id}/dynamo`)
                    .then((payload) => this.addDynamoData(payload.data, row))
                    .catch((err) => this.handleError(err)),
                );
        });

        return Promise.all(promises)
        .then((payloads) => this.items = payloads);
    }

    private addDynamoData(itemDynamo: DynamoItems, athenaData: AthenaClass) {
        const node = new TreeItem();
        // node.id = athenaData.id;
        // node.body = athenaData.body;
        // node.isExcluded = !itemDynamo ? false : itemDynamo.isExcluded;
        // node.isPreviousExcluded = false;
        // node.children = athenaData.children
        //     ? athenaData.children.map((x) => this.mergeData(itemDynamo, x, node.isExcluded))
        //     : [];
        // return node;
    }

    private mergeData(parentDynamo: DynamoItems, athenaData: AthenaClass, isParentExcluded: boolean) {
        // if (!athenaData) {
        //     return null;
        // }
        // const node = new TreeItem();
        // const itemDynamo = parentDynamo ? parentDynamo.children.find((x) => x.id === (athenaData.id + '')) : null;
        // node.id = athenaData.id;
        // node.body = athenaData.body;
        // node.isExcluded = isParentExcluded || (!itemDynamo ? false : itemDynamo.isExcluded);
        // node.isPreviousExcluded = false;
        // node.children = athenaData.children
        //     ? athenaData.children.map((x) => this.mergeData(itemDynamo, x, node.isExcluded))
        //     : [];
        // return node;
    }

    private handleError(error: any): void {
        try {
            if (error.response.status === 400) {
                alert(error.response.data);
            } else {
                alert('Server error Contact Admin');
            }
        } catch {
            alert('Server error Contact Admin');
        }
    }

}
