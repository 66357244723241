






import { Component, Vue } from 'vue-property-decorator';
import DataSourceComponent from '@/components/DataSourceComponent/dataSourceComponent.vue'; // @ is an alias to /src

@Component({
  components: {
    DataSourceComponent,
  },
})
export default class DataSource extends Vue {}
