import { Component, Vue } from 'vue-property-decorator';
import { EVENT_BUS } from './../../eventBus';

@Component
export default class ShowExcludedComponent extends Vue {

  public shouldShow: boolean = false;

  public emitClick() {
    if (this.shouldShow) {
      EVENT_BUS.$emit('exclude:show');
    } else {
      EVENT_BUS.$emit('exclude:hide');
    }
  }
}
