import Vue from 'vue';
import Router from 'vue-router';
import DataSource from './views/DataSource.vue';
import AdformMedia from './views/AdformMedia.vue';
import Home from './views/Home.vue';
Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/adform_media',
      name: 'adform_media',
      component: AdformMedia,
    },
    {
      path: '/:datasource/:country/:accountId',
      name: 'datasource',
      component: DataSource,
    },
  ],
});
